import React from 'react'

export const SVGZap = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="19.1" height="21" viewBox="0 0 19.1 21">
    <path id="Icon_feather-zap" data-name="Icon feather-zap" d="M14,3,4.5,14.4h8.55L12.1,22l9.5-11.4H13.05Z" transform="translate(-3.5 -2)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  </svg></>
  
}


export const SVGSearch = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="19.194" height="19.194" viewBox="0 0 19.194 19.194">
    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.5 -3.5)">
      <path id="Path_43" data-name="Path 43" d="M19.415,11.958A7.458,7.458,0,1,1,11.958,4.5a7.458,7.458,0,0,1,7.458,7.458Z"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_44" data-name="Path 44" d="M29.03,29.03l-4.055-4.055" transform="translate(-7.75 -7.75)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg></>
  
}


export const SVGLink = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="17.49" height="17.475" viewBox="0 0 17.49 17.475">
    <g id="Icon_feather-link" data-name="Icon feather-link" transform="translate(-1.993 -2.008)">
      <path id="Path_52" data-name="Path 52" d="M15,11.519a3.871,3.871,0,0,0,5.837.418l2.322-2.322a3.87,3.87,0,1,0-5.474-5.474L16.355,5.465" transform="translate(-5.811)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
      <path id="Path_53" data-name="Path 53" d="M12.286,15.045a3.871,3.871,0,0,0-5.837-.418L4.127,16.949A3.87,3.87,0,1,0,9.6,22.422L10.924,21.1" transform="translate(0 -5.074)"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    </g>
  </svg>
  </>
  
}

export const SVGFile = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
</svg>
    </>
}

export const SVGSettings = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
    </>
}


export const SVGLogout = () => {
   return <><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg></>
}

export const SVGFolder = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" width="19.1" height="17.39" viewBox="0 0 19.1 17.39">
    <path id="Icon_feather-folder" data-name="Icon feather-folder" d="M20.1,18.18a1.71,1.71,0,0,1-1.71,1.71H4.71A1.71,1.71,0,0,1,3,18.18V6.21A1.71,1.71,0,0,1,4.71,4.5H8.985l1.71,2.565H18.39a1.71,1.71,0,0,1,1.71,1.71Z" transform="translate(-2 -3.5)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  </svg>
  </>
}

export const SVGFolderPlus = () => {
    return <><svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512">
    <path d="M512 416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96C0 60.7 28.7 32 64 32H192c20.1 0 39.1 9.5 51.2 25.6l19.2 25.6c6 8.1 15.5 12.8 25.6 12.8H448c35.3 0 64 28.7 64 64V416zM232 376c0 13.3 10.7 24 24 24s24-10.7 24-24V312h64c13.3 0 24-10.7 24-24s-10.7-24-24-24H280V200c0-13.3-10.7-24-24-24s-24 10.7-24 24v64H168c-13.3 0-24 10.7-24 24s10.7 24 24 24h64v64z"/>
</svg></>
}

export const SVGFolderFilled = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="21.823" height="19.84" viewBox="0 0 21.823 19.84">
  <g id="Icon_feather-folder-plus" data-name="Icon feather-folder-plus" transform="translate(-2 -3.5)">
    <path id="Path_874" data-name="Path 874" d="M22.823,20.358A1.982,1.982,0,0,1,20.84,22.34H4.982A1.982,1.982,0,0,1,3,20.358V6.482A1.982,1.982,0,0,1,4.982,4.5H9.938L11.92,7.473h8.92a1.982,1.982,0,0,1,1.982,1.982Z"  stroke="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Path_875" data-name="Path 875" d="M18,16.5v5.472" transform="translate(-5.089 -3.754)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
    <path id="Path_876" data-name="Path 876" d="M13.5,21h5.472" transform="translate(-3.325 -5.598)" fill="none"  strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"/>
  </g>
</svg>

</>
}

export const SVGQuestion = () => {
  return <><svg viewBox="0 0 24 24">
  <path d="M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15M14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2"></path>
</svg></>
}


export const SVGMorevet = () => {
  return <><svg viewBox="0 0 24 24" data-testid="MoreVertIcon" ><path d="M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2m0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2m0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2"></path></svg></>
}

export const SVGTTrash = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 96 960 960"><path d="M261 936q-24.75 0-42.375-17.625T201 876V306h-41v-60h188v-30h264v30h188v60h-41v570q0 24-18 42t-42 18H261Zm438-630H261v570h438V306ZM367 790h60V391h-60v399Zm166 0h60V391h-60v399ZM261 306v570-570Z"/></svg></>
}

export const SVGEditPencil = () => {
  return <><svg viewBox="0 0 24 24" version="1.1" >
  <g>
  <path d="M 4.5 19.5 L 5.601562 19.5 L 16.675781 8.425781 L 15.574219 7.324219 L 4.5 18.398438 Z M 19.851562 7.351562 L 16.648438 4.148438 L 17.699219 3.101562 C 17.984375 2.816406 18.332031 2.675781 18.75 2.675781 C 19.167969 2.675781 19.515625 2.816406 19.800781 3.101562 L 20.898438 4.199219 C 21.183594 4.484375 21.324219 4.832031 21.324219 5.25 C 21.324219 5.667969 21.183594 6.015625 20.898438 6.300781 Z M 18.800781 8.398438 L 6.199219 21 L 3 21 L 3 17.800781 L 15.601562 5.199219 Z M 16.125 7.875 L 15.574219 7.324219 L 16.675781 8.425781 Z M 16.125 7.875 " ></path>
  </g>
  </svg></>
}


export const SVGClear = () => {
  return <></>
}


export const SVGPDFUpload = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="22.075" height="22.075" viewBox="0 0 22.075 22.075">
  <g id="upload-minimalistic-svgrepo-com" transform="translate(-2.25 -2.25)">
    <path id="Path_1" data-name="Path 1" d="M13.254,2.526a.849.849,0,0,0-1.253,0L7.472,7.479A.849.849,0,1,0,8.726,8.625l3.053-3.339v12.53a.849.849,0,1,0,1.7,0V5.286l3.053,3.339a.849.849,0,1,0,1.253-1.146Z" transform="translate(0.66 0)" />
    <path id="Path_2" data-name="Path 2" d="M3.948,15.1a.849.849,0,0,0-1.7,0v.062a31.039,31.039,0,0,0,.132,3.778A4.208,4.208,0,0,0,3.493,21.5a4.208,4.208,0,0,0,2.558,1.112,31.058,31.058,0,0,0,3.778.132h6.917a31.058,31.058,0,0,0,3.778-.132A4.208,4.208,0,0,0,23.082,21.5a4.208,4.208,0,0,0,1.111-2.558,31.058,31.058,0,0,0,.132-3.778V15.1a.849.849,0,0,0-1.7,0,30.429,30.429,0,0,1-.117,3.613,2.552,2.552,0,0,1-.629,1.584,2.552,2.552,0,0,1-1.584.629,30.429,30.429,0,0,1-3.613.117H9.891a30.428,30.428,0,0,1-3.613-.117A2.552,2.552,0,0,1,4.694,20.3a2.552,2.552,0,0,1-.629-1.584A30.411,30.411,0,0,1,3.948,15.1Z" transform="translate(0 1.585)" />
  </g>
</svg></>
}

export const SVGPDFEdit = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="20.072" height="19.955" viewBox="0 0 20.072 19.955">
  <path id="edit-svgrepo-com_3_" data-name="edit-svgrepo-com (3)" d="M21.455,9.493,9.875,21.073a2.46,2.46,0,0,1-1.257.673L5,22.47l.724-3.618A2.46,2.46,0,0,1,6.4,17.594l11.58-11.58m3.479,3.479,1.739-1.739a1.23,1.23,0,0,0,0-1.739L21.455,4.274a1.23,1.23,0,0,0-1.739,0L17.976,6.014m3.479,3.479L17.976,6.014" transform="translate(-4.3 -3.214)" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.4"/>
</svg></>
}

export const SVGPDFSave = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  <path id="save-svgrepo-com" d="M18.172,1a2,2,0,0,1,1.414.586l2.828,2.828A2,2,0,0,1,23,5.828V20a3,3,0,0,1-3,3H4a3,3,0,0,1-3-3V4A3,3,0,0,1,4,1ZM4,3A1,1,0,0,0,3,4V20a1,1,0,0,0,1,1H5V15a3,3,0,0,1,3-3h8a3,3,0,0,1,3,3v6h1a1,1,0,0,0,1-1V6.828a2,2,0,0,0-.586-1.414L18.586,3.586A2,2,0,0,0,17.172,3H17V5a3,3,0,0,1-3,3H10A3,3,0,0,1,7,5V3ZM17,21V15a1,1,0,0,0-1-1H8a1,1,0,0,0-1,1v6ZM9,3h6V5a1,1,0,0,1-1,1H10A1,1,0,0,1,9,5Z" transform="translate(-1 -1)" fillRule="evenodd"/>
</svg></>
}

export const SVGPDFIcon = () => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="43.187" height="54.077" viewBox="0 0 43.187 54.077">
  <path id="pdf-svgrepo-com" d="M34.7,35.28a6.675,6.675,0,0,1-2.664.066,12.7,12.7,0,0,1-3.365-1,9.993,9.993,0,0,1,4.855.268A5.307,5.307,0,0,1,34.7,35.28ZM23.6,33.455l-.24.065c-.535.146-1.057.288-1.559.415l-.677.172c-1.362.345-2.754.7-4.13,1.116.523-1.26,1.008-2.535,1.484-3.781.352-.922.712-1.864,1.083-2.794q.283.467.59.936A18.229,18.229,0,0,0,23.6,33.455ZM20.134,19.241a11.748,11.748,0,0,1-.74,4.487,7.186,7.186,0,0,1-.131-5.317,3.052,3.052,0,0,1,.457-.736A3.447,3.447,0,0,1,20.134,19.241Zm-7.11,19.7c-.341.609-.688,1.18-1.045,1.719-.86,1.3-2.267,2.684-2.989,2.684a.356.356,0,0,1-.283-.144.27.27,0,0,1-.09-.229,4.257,4.257,0,0,1,1.571-2.112A15.465,15.465,0,0,1,13.024,38.943ZM37,35.348c-.11-1.587-2.783-2.606-2.809-2.615a10.122,10.122,0,0,0-3.431-.544,20.551,20.551,0,0,0-4.728.639,16.453,16.453,0,0,1-4.221-4.338c-.479-.73-.91-1.459-1.286-2.171a16.088,16.088,0,0,0,1.593-7.192c-.121-2.118-1.076-3.54-2.374-3.54-.891,0-1.658.66-2.281,1.963-1.113,2.322-.82,5.293.869,8.838-.608,1.429-1.173,2.91-1.72,4.343-.681,1.783-1.382,3.622-2.172,5.372a19.187,19.187,0,0,0-5.555,3.246c-.994.854-2.193,2.159-2.261,3.522a2.252,2.252,0,0,0,.634,1.7,2.344,2.344,0,0,0,1.728.765c2.167,0,4.253-2.978,4.649-3.575a32.353,32.353,0,0,0,2.272-4.084c1.84-.665,3.8-1.161,5.7-1.641l.681-.173c.512-.13,1.043-.274,1.589-.423.577-.156,1.171-.318,1.774-.472a15.7,15.7,0,0,0,6.1,2.347,7.324,7.324,0,0,0,4.291-.435A1.577,1.577,0,0,0,37,35.348ZM41.2,49a2.93,2.93,0,0,1-3.079,3.092H5.063A2.933,2.933,0,0,1,1.986,49V5.078A2.929,2.929,0,0,1,5.062,1.986H27.394L27.406,2v8.715a4.88,4.88,0,0,0,5.062,5.06h8.658l.074.074ZM39.148,13.786H32.469a2.92,2.92,0,0,1-3.075-3.074V3.992ZM43.187,49V15.028L29.394,1.178V1.113h-.066L28.22,0H5.063A4.888,4.888,0,0,0,0,5.079V49a4.891,4.891,0,0,0,5.063,5.079H38.125A4.889,4.889,0,0,0,43.187,49Z" fill="#eb5757"/>
</svg></>
}