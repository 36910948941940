import { Palette } from '@material-ui/icons';
import { darkPalette, lightPalette } from './palette';

export const tableStyle = {
  MuiTable: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: 0,
      '& td:first-child': {
        borderTopLeftRadius: '10px',
        borderBottomLeftRadius: '10px'
      },
      '& td:last-child': {
        borderTopRightRadius: '10px',
        borderBottomRightRadius: '10px'
      },
      '& .inner-details': {
        '&:first-child': {
          borderTopLeftRadius: '0px'
        },
        '&:last-child': {
          borderTopRightRadius: '0px'
        }
      }
    }
  },
  MuiTableRow: {
    root: {
      '&$selected': {
        '& .MuiTableCell-body': {
          '&:first-child': {
            borderBottomLeftRadius: '0px'
          },
          '&:last-child': {
            borderBottomRightRadius: '0px'
          }
        }
      }
    }
  },
  MuiTableCell: {
    root: {
      padding: '10px',
      '& .MuiButtonBase-root': {
        textTransform: 'none',
        padding: '4px 10px',
        '& .MuiButton-label': {
          fontSize: '0.85rem'
        },
        '& .MuiIconButton-label': {
          '& svg': {
            width: '18px'
          }
        },
        '& .MuiButton-startIcon': {
          width: '18px'
        }
      }
    },
    head: {
      fontWeight: 600,
      fontSize: '14px',
      textTransform: 'uppercase',
      borderBottom: ' none',
      '&:first-child': {
        paddingLeft: '50px'
      }
    },
    body: {
      fontSize: '16px',
      '&:first-child': {
        paddingLeft: '50px'
      }
    }
  },
  MuiCollapse: {
    entered: {
      padding: '0px 15px 10px 15px'
    },
    wrapperInner: {
      '& .MuiTableCell-head': {
        borderBottom: 'none'
      },
      '& .workflow-error': {
        '& td:first-child': {
          borderRadius: 0
        },
        '& td:last-child': {
          borderRadius: 0
        },
        '& td': {
          borderTop: 'none',
          borderBottom: 'none'
        },
        '& label': {
          fontWeight: 600,
          fontSize: '0.68rem',
          textTransform: 'uppercase'
        },

        '& .MuiButton-text': {
          padding: '0px',
          '& .MuiButton-label': {
            color: '#8985FF',
            fontSize: '0.85rem',
            '& .MuiButton-endIcon': {
              margin: '-3px 4px 0px 3px',
              width: '15px'
            }
          }
        }
      }
    }
  }
};

export const darkTable = {
  MuiTable: {
    root: {
      borderCollapse: 'separate',
      borderSpacing: 0,
      '& td:first-child': {
        borderLeft: '1px solid #1C2026'
      },
      '& td:last-child': {
        borderRight: '1px solid #1C2026'
      },
      '& .inner-details': {
        borderBottom: '1px solid #505867',

        '&:first-child': {
          borderLeft: '1px solid #505867'
        },
        '&:last-child': {
          borderRight: '1px solid #505867'
        }
      }
    }
  },
  MuiTableRow: {
    root: {
      '&$selected': {
        '& .MuiTableCell-body': {
          borderTop: '1px solid #505867',
          borderBottom: '1px solid #505867',
          '&:first-child': {
            borderLeft: '1px solid #505867'
          },
          '&:last-child': {
            borderRight: '1px solid #505867'
          }
        }
      }
    }
  },
  MuiTableCell: {
    head: {
      color: '#505867'
    },
    body: {
      backgroundColor: '#282C34',
      borderTop: '1px solid #1C2026',
      borderBottom: '5px solid #1C2026'
    }
  },
  MuiCollapse: {
    wrapperInner: {
      '& .MuiTableCell-body': {
        backgroundColor: '#313640',
        borderTop: '1px solid #282C34',
        borderBottom: '5px solid #282C34',
        '&:first-child': {
          borderLeft: '1px solid #282C34'
        },
        '&:last-child': {
          borderRight: '1px solid #282C34'
        }
      },
      '& .workflow-error': {
        '& td:first-child': {
          borderLeft: '3px solid #F44336'
        },
        '& label': {
          color: '#505867'
        }
      }
    }
  }
};

const paletteConfig = {
  light: {
    palette: lightPalette
  },
  dark: {
    palette: darkPalette
  }
};

export const designtable = mode => {
  const theme = mode == 'light' ? paletteConfig.light : paletteConfig.dark;

  return {
    MuiTable: {
      root: {
        borderCollapse: 'separate',
        borderSpacing: 0,
        '& td:first-child': {
          borderLeft: '1px solid ' + theme.palette.grey[700]
        },
        '& td:last-child': {
          borderRight: '1px solid ' + theme.palette.grey[700]
        },
        '& .inner-details': {
          borderBottom: '1px solid ' + theme.palette.grey[500],

          '&:first-child': {
            borderLeft: '1px solid ' + theme.palette.grey[500]
          },
          '&:last-child': {
            borderRight: '1px solid ' + theme.palette.grey[500]
          }
        }
      }
    },
    MuiTableRow: {
      root: {
        '&$selected': {
          '& .MuiTableCell-body': {
            borderTop: '1px solid ' + theme.palette.grey[500],
            borderBottom: '1px solid ' + theme.palette.grey[500],
            '&:first-child': {
              borderLeft: '1px solid ' + theme.palette.grey[500]
            },
            '&:last-child': {
              borderRight: '1px solid ' + theme.palette.grey[500]
            }
          }
        }
      }
    },
    MuiTableCell: {
      root: {
        '& .MuiIconButton-root': {
          color: theme.palette.text.primary
        }
      },
      head: {
        color: theme.palette.grey[500]
      },
      body: {
        backgroundColor: theme.palette.background.default,
        borderTop: '1px solid ' + theme.palette.grey[700],
        borderBottom: '5px solid ' + theme.palette.grey[700]
      }
    },
    MuiCollapse: {
      wrapperInner: {
        '& .MuiTableCell-body': {
          backgroundColor: theme.palette.grey[200],
          borderTop: '1px solid ' + theme.palette.background.default,
          borderBottom: '5px solid ' + theme.palette.background.default,
          '&:first-child': {
            borderLeft: '1px solid ' + theme.palette.background.default
          },
          '&:last-child': {
            borderRight: '1px solid ' + theme.palette.background.default
          }
        },
        '& .workflow-error': {
          '& td:first-child': {
            borderLeft: '3px solid #F44336'
          },
          '& label': {
            color: theme.palette.grey[500]
          }
        }
      }
    }
  };
};
