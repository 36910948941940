import React, {
  createContext,
  useEffect,
  useReducer,
  createElement,
  useState
} from 'react';
import axios from 'axios';
import type { FC, ReactNode } from 'react';
import type { User } from 'src/types/user';
import SplashScreen from 'src/components/SplashScreen';
import firebase from 'src/lib/firebase';

import {
  activepiecesRequest,
  activepiecesFrontendURL
} from '../utils/requestUrl'

// This should also be in the Firebase Console list of authorized domains
const getRedirectUrlForAuth = () => {
  let authCheckUrl = `${window.location.host}/authcheck`;
  let prefix = window.location.hostname === 'localhost' ? 'http' : 'https';
  return `${prefix}://${authCheckUrl}`;
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth',
  logout: () => Promise<void>;
  signInWithGoogle: () => Promise<any>;
  sendSignInLinkToEmail: (email: string) => Promise<any>;
  isValidSignInEmailLink: (url: string) => boolean;
  signInWithValidEmailLink: (email: string, url: string) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type Action = AuthStateChangedAction;

export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

 const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'AUTH_STATE_CHANGED': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  logout: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  sendSignInLinkToEmail: () => Promise.resolve(),
  isValidSignInEmailLink: () => false,
  signInWithValidEmailLink: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const sendSignInLinkToEmail = (email: string): Promise<any> => {
    return firebase.auth().sendSignInLinkToEmail(
      email, { url: getRedirectUrlForAuth(), handleCodeInApp: true }
    );
  };

  const isValidSignInEmailLink = (url: string): boolean => {
    return firebase.auth().isSignInWithEmailLink(url);
  }

  const signInWithValidEmailLink = (email: string, url: string): Promise<any> => {
    return firebase.auth().signInWithEmailLink(email, url);
  }

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const logout = (): Promise<void> => {
    return firebase.auth().signOut();
  };

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged( (user) => {
      if (user) {
        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.
        user.getIdTokenResult()
          .then(async (idTokenResult) => {
            // complete successful sign in once we have the id token
            //console.log('id token', idTokenResult);
            // dispatch the user data + id token
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: true,
                user: {
                  id: user.uid,
                  idToken: idTokenResult,
                  avatar: user.photoURL,
                  email: user.email,
                  name: user.displayName || user.email,
                  tier: 'Premium',
                }
              }
            });
         

          })
          .catch((err) => {
            console.error('err for getting id token', err);
            dispatch({
              type: 'AUTH_STATE_CHANGED',
              payload: {
                isAuthenticated: false,
                user: null
              }
            });
          });
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    });

    return unsubscribe;
  }, [dispatch]);




  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'FirebaseAuth',
        logout,
        signInWithGoogle,
        sendSignInLinkToEmail,
        isValidSignInEmailLink,
        signInWithValidEmailLink,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
