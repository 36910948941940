import React, {  useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import {  makeStyles } from '@material-ui/core';
import type { Theme } from 'src/theme';
import NavBar from './NavBar';
import TopBar from './TopBar';
import SideBar from './SideBar';
import LoadingWorkflow from 'src/components/LoadingWorkflow';


interface DashboardLayoutProps {
  children?: ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    //paddingTop: 64,

  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout: FC<DashboardLayoutProps> = ({ children }) => {

  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>(false);
  const [isLoadingWorkFlow, setLoadingWorkflow] = useState(false)


  return (
    <>
    <div className={classes.root} style={{position: 'relative'}}>
     {/** <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} /> */}
      <SideBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
        setLoadingWorkflow={setLoadingWorkflow}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
          <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
            {children}
          </div>
        </div>
      </div>
      
    </div>
    {
      isLoadingWorkFlow && <LoadingWorkflow  />
    }
    </>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
