const isLocalhost = () => window.location.hostname === 'localhost';

const PROD_GALAXY_SERVER_URL = 'https://prod-galaxy.getkosmos.io';
const PROD_NODE_SERVER_URL = 'https://kosmos-node-server-dot-magnus-9514.ue.r.appspot.com';


const PROD_ACTIVEPIECES_FRONTEND_URL = 'https://create.getkosmos.io'
const PROD_ACTIVEPIECES_BACKEND_URL = 'https://create.getkosmos.io/api/v1'

export const nodeServerURL = () => {
  return isLocalhost() ? 'http://localhost:8081' : PROD_NODE_SERVER_URL;
};

export const galaxyServerURL = () => {
  return isLocalhost() ? 'http://localhost:8080' : PROD_GALAXY_SERVER_URL;
};

export const activepiecesFrontendURL = () => {
  return isLocalhost() ? 'http://localhost:4200' : PROD_ACTIVEPIECES_FRONTEND_URL;
};

export const activepiecesBackendURL = () => {
  return isLocalhost() ? 'http://localhost:3001/v1' : PROD_ACTIVEPIECES_BACKEND_URL;
};


const kosmosAIURL = () => {
  const urls = [
    'http://127.0.0.1:5000', //local
    'https://getkosmos.io/kosmos-pdf-extract', //dev
    'https://getkosmos.io/kosmos-pdf-extract', //prod
  ]

  const type = (isLocalhost()) ? 0 : 1

  return urls[type] 
}

export const kosmosAIRequest = {
  stringChecker: kosmosAIURL() + '/check/string',
  pdfExtract: kosmosAIURL() + '/extract/pdf',
}


export const activepiecesRequest = {
  validateToken: activepiecesBackendURL() + '/kosmos/auth/validateToken',
  requestToken: activepiecesBackendURL() + '/kosmos/auth/validateToken',
  flowsFolders: activepiecesBackendURL() + '/activepieces/folders'
}