import React from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import { THEMES } from 'src/constants';
import type { Theme } from 'src/theme';
import Account from './Account';

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: '0px',
    color: theme.palette.text.secondary
  },
  burgerIcon:{
    padding: '12px 0px 0px 0px'
  }
}));

const TopBar: FC<TopBarProps> = ({
  className,
  onMobileNavOpen,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Hidden lgUp>
      <Box>
        <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={onMobileNavOpen}
              className={classes.burgerIcon}
            >
              <SvgIcon fontSize="small">
                <MenuIcon />
              </SvgIcon>
            </IconButton>

        </Toolbar>
      </Box>
    </Hidden>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
  onMobileNavOpen: () => { }
};

export default TopBar;
