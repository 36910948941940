
import React, { useRef, useState } from 'react'
import type {FC} from 'react'
import type { Theme } from 'src/theme';

import { 
    Menu, 
    makeStyles 
} from '@material-ui/core';

import { activepiecesFrontendURL } from 'src/utils/requestUrl';

import { SVGMorevet,SVGEditPencil, SVGTTrash } from 'src/components/SVGIcon';

interface NavFolderItem {
    classes: string
    path: string
    SVGIcon: object
    NavName: string
    item: any,
    setIsDeleteModal: any
    setIsRenameModal: any
    setSelecteditem: any
}

const useStyles = makeStyles((theme: Theme) => ({
    root:{},
    container:{
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& .menu-container':{
            display: 'none'
        },
        '&:hover':{
            '& .menu-container':{
                display: 'flex'
            },
        }
 
    },
    inner:{
        width: '100%',
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        height: '24px',
        color: '#ffffff',
        '& .svgContainer':{
            marginTop: '1px',
            display: 'flex',
            width: '35px',
            fill: 'none',
            stroke: theme.palette.grey[100],
        },

        '& span':{
            color: theme.palette.grey[50],
            display: 'flex',
            flex: 1
        },

  
    },
    menuContainer:{
        '& .dot':{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            width: '20px',
            '& svg':{
                fill: theme.palette.grey[100],
            }
        },  
    },
    menuItem:{
        padding: '8px 0px',
        '& button':{
            cursor: 'pointer',
            border: 'none',
            backgroundColor: 'inherit',
            display: 'flex',
            padding: '8px 16px',
            color: theme.palette.grey[50],
            fontSize: '16px',
            '& .btn-inner':{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: '10px',
                '& svg':{
                    width: '20px',
                    height: '20px'
                }
            },
            '& .rename':{
                fill: theme.palette.grey[50],
            },
            '& .delete':{
                color: '#dc3545',
                fill: '#dc3545',
            }
        }
    }

}))

const NavFolderItem: FC<NavFolderItem> = ({ path, SVGIcon, NavName, item, setSelecteditem, setIsRenameModal, setIsDeleteModal} ) => {

    const classes = useStyles();

    const [isOpen, setOpen] = useState(false)
    const ref = useRef(null)

    const navigateTo = (path: string): void => {

        const openLink = activepiecesFrontendURL() + path
        window.location.href = openLink
  

    }   

    const handleRename = (item: any)  =>{
        setOpen(false)
        setSelecteditem(item)
        setIsRenameModal(true)
    }

    const handleDelete = (item: any) => {
        setOpen(false)
        setSelecteditem(item)
        setIsDeleteModal(true)
    }

  return (
    <>

        <div className={classes.container}>
            <button type='button' className='folder-item' onClick={() => navigateTo(path)} style={{width: '100%'}}> 
                <div className={classes.inner}>
                    <div className='svgContainer' >
                        {SVGIcon}
                    </div>
                    <span>
                        {NavName}
                    </span>
                </div>
            </button>

            <div className={`${classes.menuContainer} menu-container`} >
                <button type='button' className='dot' onClick= { () => setOpen(true) } ref={ref} >
                    {SVGMorevet()}
                </button>
                <Menu
                    onClose={() => setOpen(false)}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                    }}
                    keepMounted
                    getContentAnchorEl={null}
                    anchorEl={ref.current}
                    open={isOpen}
                >
                    <div className={classes.menuItem} >
                        <button type='button' onClick={() => handleRename(item)}>
                            <div className='btn-inner rename'>
                                <div>
                                    {SVGEditPencil()}
                                </div>
                                <span>Rename</span>
                            </div>
                        </button>
                    </div>
                    <div className={classes.menuItem}>
                        <button type='button' onClick={ () => handleDelete(item)}>
                            <div className='btn-inner delete'>
                                <div>
                                    {SVGTTrash()}
                                </div>
                                <span>Delete</span>
                            </div>
                        </button>
                    </div>
                </Menu>
            </div>
        </div>
    </>
  )
}

export default NavFolderItem