import React from 'react';
import type { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

interface AuthGuardProps {
  children?: ReactNode;
}

const redirectToKosmosLanding = () => {
  window.location.replace('https://getkosmos.io');
}

const handleUnlinkedUser = (logoutFn: () => Promise<void>) => {
  let waitSec = 20;
  console.log(`logging user out and redirecting in ${waitSec}`);
  logoutFn().then(() => {
    window.setTimeout(() => redirectToKosmosLanding(), waitSec * 1000)
  });
}

const AuthGuard: FC<AuthGuardProps> = ({ children }) => {
  const { isAuthenticated, user, logout } = useAuth();

  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  // console.log('auth guard ', user);
  let claims = user.idToken.claims;

  if (claims['firm'] == null || claims['role'] == null) {
    handleUnlinkedUser(logout);
    return <Redirect to="/setup-required" />;
  }

  return (
    <>
      {children}
    </>
  );
};

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default AuthGuard;
