import React from 'react';

import { 
  makeStyles 
} from '@material-ui/core';

import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root:{
    position:'absolute',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    top:0,
    left: 0,
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.background.dark,
    opacity: 0.9,
    zIndex: 2,


  '& .header':{
    width: '263px',
    height: '253px',
    border: `1px solid #e0e4e8`,
    borderRadius:'10px',

    '& img':{
      width: '100%',
      height: '100%',
    }
  },
  '& .description':{
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: '30px',
    marginTop: '40px',
    color: theme.palette.text.primary
  },
  '& .loading-wrapper':{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'start',
    backgroundColor: '#707070',
    width: '400px',
    borderRadius: '90px',
    height: '15px', 
    marginTop:'20px',
    '& .loading-process':{
      backgroundColor: '#2FC1FB',
      borderRadius: '90px',
      height: '15px'
    }
  },
    
  },
  

  
})) 
const LoadingWorkflow = ( ) => {

  const classes = useStyles();

  return <div className={classes.root}>
      <div
        className='header'
      >
        <img
          src="/static/images/loading-icon.png"
          alt="Loading..."
        />
      </div>
      <p
        className='description'
      >
        Loading...
      </p>
      <div
        className='loading-wrapper'
      >
        <div className='loading-process' style={{
          width: '67%'
        }} ></div>
      </div>
  </div>;
};

export default LoadingWorkflow;
