import React, {useState} from 'react'
import type {FC} from 'react'

import {  Dialog, DialogContent, Modal, TextField, makeStyles } from '@material-ui/core'

import type { Theme } from 'src/theme';

import { activepiecesRequest } from 'src/utils/requestUrl';

import axios from 'axios';
import useAuth from 'src/hooks/useAuth';

interface RenameModalProps {
    isOpen: boolean,
    setFolders:any,
    selectedItem: any
    setSelecteditem: any
    handleClose: () => void,
}
const useStyles = makeStyles((theme: Theme) => ({
    root:{
    },
    dialogContent:{
        backgroundColor: theme.palette.background.card,
        width: '548px',
        fontFamily: 'Roboto',
        fontSize: '16px',
        letterSpacing: '0.5px',
        padding: 0,

        '& .dialog-header':{
            fontSize: '1.25rem',
            fontWeight: 400,
            borderBottom: '1px solid ' + theme.palette.background.icon,
            padding: '0 24px 9px',
        },
        '& .dialog-content':{
            padding: '3px 24px 20px 24px',
            '& .dialog-description':{
                paddingTop: '20px',
                marginBottom: '1rem',
            },
            '& .MuiFormLabel-root':{
                color: theme.palette.grey[20]
            },
            '& .MuiFormLabel-root.Mui-error':{
                color: '#dc3545'
            },
            '& .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.grey[800]
            },
            '& .Mui-error':{
                '& .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#dc3545'
                },
            },
            '& .MuiFormHelperText-root.Mui-error':{
                fontSize: '12px'
            },
            '& .input-text':{
                width: '100%'
            }
        },
        '& .dialog-footer':{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: '0.625rem',
            padding: '8px',

            '& button':{
                border: 'none',
                fontSize: '16px',
                padding: '0 16px 0 16px',
                height: '36px',
                cursor: 'pointer'

            },
            '& .cancel':{
                borderRadius: '4px',
                backgroundColor: 'transparent',
                color:'#fff'
            },
            '& .rename':{
                borderRadius: '4px',
                backgroundColor: '#2fc1fb',
                color:'#fff'
            }
        }
    }
}))

const RenameModal:FC<RenameModalProps> = ({selectedItem, setSelecteditem, setFolders, isOpen = false, handleClose, }) => {
    const { user } = useAuth()
    const classes = useStyles()
    const [loading, setLoading] = useState(false)
    const [textValue, setTextValue] = useState(selectedItem.displayName)

    const [error, setError] = useState({
        isError: false,
        message: ''
    })

    const handleSubmit = async() =>{

        if(textValue.trim() == '') {
            setError( {
                isError: true,
                message: 'Name is required'
            })
            return 
        }

            setLoading(true)
            try {
                const token = user.idToken.token 
    
                const config = {
                    headers:{
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }  

                const rawBody = {
                    "displayName": textValue
                }
                
                const url = activepiecesRequest.flowsFolders + `/${selectedItem.id}`

                const { data } = await axios.put(url,rawBody, config)
             

                setFolders( prev => prev.filter(item => {
                    if(item.id == selectedItem.id){
                        item.displayName = data.displayName
                    }
                    return item
                }))

                
                setLoading(false)
                handleClose()
                setSelecteditem(null)
    
            } catch (error) {
                setLoading(false)
                let msg = ''
                const errResponse = error.response
                const { data } = errResponse
                if(data.params?.message){
                    msg = data.params?.message
                }else{
                    msg = 'Something went wrong'
                }
                setError( {
                    isError: true,
                    message: msg
                })
            }
 

    }

    const onChangeTextValue = (e) => {
        const text = e.target.value
        setTextValue(text)
        if(text.length > 0){
            setError( {
                isError: false,
                message: ''
            })
            
        }  
        
    }

  return (
    <div className={classes.root}>
        <Dialog
          open={isOpen}
        >
            <DialogContent className={classes.dialogContent}>
                <form id="form-mdal">
                    <div className='dialog-header'>{`Rename Folder (${selectedItem.displayName})`}</div>
                    <div className='dialog-content'>
                        <div className='dialog-description'>
                            <p>Change the name of your folder</p>
                        </div>
                        <TextField
                            error={error.isError}
                            disabled={loading}
                            label={""}
                            variant="outlined"
                            value={textValue}
                            className='input-text'
                            onChange={onChangeTextValue}
                            helperText={error.message}
                        />

                    </div>
                    <div className='dialog-footer'>
                        <button type='button' className='cancel' onClick={handleClose} disabled={loading}>Cancel</button>
                        <button type='button' className='rename' onClick={handleSubmit} disabled={loading}>Done</button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    </div>
  )
}

export default RenameModal