
export const firebaseConfig = {
  apiKey: "AIzaSyCl7nicQuIy6r83mvbp-FGt6tPGwsfu2E8",
  authDomain: "kosmos-6b79.firebaseapp.com",
  databaseURL: "https://kosmos-6b79.firebaseio.com",
  projectId: "kosmos-6b79",
  storageBucket: "kosmos-6b79.appspot.com",
  messagingSenderId: "581890174202",
  appId: "1:581890174202:web:aa44cac07c667df236ed43",
  measurementId: "G-34WN4403YT"
};
