import type { Palette } from "@material-ui/core/styles/createPalette"

export const darkPalette = {
    type: 'dark',
    action: {
        active: 'rgba(255, 255, 255, 0.54)',
        hover: 'rgba(255, 255, 255, 0.04)',
        selected: 'rgba(255, 255, 255, 0.08)',
        disabled: 'rgba(255, 255, 255, 0.26)',
        disabledBackground: 'rgba(255, 255, 255, 0.12)',
        focus: 'rgba(255, 255, 255, 0.12)'
    },
    primary:{
        light: 'rgba(47, 193, 251, 1)',
        main: 'rgba(47, 193, 251, 1)'
    },
    secondary:{
        main: '#505867'
    },
    grey:{
        20: '#505867',
        50: '#FFFFFF',
        100: '#505867',
        200: '#313640',
        300: '#FFFFFF',
        500: '#505867',
        700: '#1C2026',
        800: '#505867',
        900: '#444A57',
    },
    background: {
        default: '#282C34',
        paper: '#282C34',
        dark: '#1C2026',
        sidebar: '#282C34',
        bg: '#1C2026',
        card: '#282C34',
        icon: '#505867',
        nav: '#a6b1bf',
    },
    text:{
        primary: '#FFFFFF',
        disabled: '#FFFFFF',
    }
}

export const lightPalette = {
    type: 'light',
    primary:{
        light: '#2FC1FB',
        main: '#8985FF'
    },
    secondary:{
        main: '#2FC1FB'
    },
    grey:{
        20: '#505867',
        50: '#FFFFFF',
        100: '#FFFFFF',
        200: '#F5F6F8',
        300: '#FFFFFF',
        500: '#9E9E9E',
        700: '#F5F6F8',
        800: '#e9e9e9',
        900: '#e9e9e9',
    },
    background: {
        default: '#FFFFFF',
        paper: '#FFFFFF',
        dark: '#F5F6F8',
        sidebar: '#3949AB',
        bg: '#3949AB',
        card: '#FFFFFF',
        icon: '#505867',
        nav: '#ffffff'
    },
    text:{
        primary: '#263238',
        disabled: '#263238',
    }

}