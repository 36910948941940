import React, { useEffect, useRef, useState } from 'react'
import type {FC} from 'react'

import { 
    makeStyles 
} from '@material-ui/core';


import type { Theme } from 'src/theme';
import Logo from 'src/components/Logo';

import {
    SVGZap,
    SVGSearch,
    SVGLink,
    SVGFile,
    SVGSettings,
    SVGLogout,
    SVGFolder,
    SVGFolderPlus,
    SVGFolderFilled,
} from 'src/components/SVGIcon';

import NavItem from './NavItem'
import NavChildrenItem from './NavChildrenItem';
import NavFolderItem from './NavFolderItem';
import HelpButton from './HelpButton';
import useAuth from 'src/hooks/useAuth';
import axios from 'axios';

import { activepiecesRequest } from 'src/utils/requestUrl'
import AddModal from 'src/components/activepieces/AddModal';
import DeleteModal from 'src/components/activepieces/DeleteModal';
import RenameModal from 'src/components/activepieces/RenameModal';

interface SideBarProps {
    onMobileClose: () => void;
    openMobile: boolean;
    setLoadingWorkflow: any;
}

const useStyles = makeStyles((theme: Theme) => ({
    root:{
        fontFamily: 'Roboto',
        fontSize: '16px',
        display:'flex',
        flexDirection: 'column',
        width: '296px',
        height: '100%',
        padding: '18px',
        backgroundColor: theme.palette.background.sidebar,
        '& button':{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundColor: 'transparent',
            border: 'none',
            fontFamily: 'Roboto',
            fontSize: '16px',
        },
        '& .navButton':{
            marginBottom: '15px',
        },
        '& .subNavButton':{
            '& button':{
                marginBottom: '5px',
            }
        },
        '& .activeLink':{
            '& span':{
                color: '#2fc1fb',  
            },
            '& svg':{
                stroke: '#2fc1fb'
            }
        },
        '& .selectedLink':{
            '& span':{
                color: '#2fc1fb',  
            },
        }
    },
    logoContainer:{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    logo:{
        marginBottom: '49px',
        marginTop: '25px',
    },
    inner:{
        width: '100%',
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        height: '24px',
        color: '#ffffff',
        '& .btn-options':{
            isplay: 'none'
        },
        '&:hover':{
            '& .btn-options':{
                    display: 'flex'
            }
        },
        '& .svgContainer':{
            marginTop: '1px',
            display: 'flex',
            width: '35px',
            fill: 'none',
            stroke: theme.palette.grey[100],
        },

        '& span':{
            color: theme.palette.grey[50],
            display: 'flex',
            flex: 1
        },

  
    },
    subNav:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        paddingLeft: '32px',
        paddingBottom: '10px',
        fill: 'none',
        '& .svgContainer':{
            display: 'flex',
            width: '32px',
            fill: 'none',
        },

        '& .filled':{
            fill: theme.palette.grey[100],
            stroke: theme.palette.text.primary,
            
            '& svg':{
                width: '20.7px',
                height: '20.7px',
            }
        }

    },

    divider:{
        width: '100%',
        border: `1px solid ${theme.palette.grey[100]}`,
        fontWeight: 'bold',
        margin: '16px 5px',
    },
    navHead: {
        width: '100%',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        margin: '11px 0px 12px 0px',
        padding: '0px 5px',
        color: theme.palette.grey[100],
    },



}))


const SideBar: FC<SideBarProps> = ({ onMobileClose, openMobile, setLoadingWorkflow }) => {
    const { user } = useAuth()
    const classes = useStyles();

    const [isAddModal, setIsAddModal] = useState(false)
    const [isRenameModal, setIsRenameModal] = useState(false)
    const [isDeleteModal, setIsDeleteModal] = useState(false)
    const [selectedItem, setSelecteditem] = useState(null)

    const [folders, setFolders] = useState(null)
  
    useEffect(() => {
        var mounted = true 
        
        const getFlowFolders = async() => {
            if(mounted){
                const token = user.idToken.token 
                const config = {
                    headers:{
                        "Content-Type": 'application/json',
                        "Authorization": `Bearer ${token}`
                    }
                }
                axios.get(activepiecesRequest.flowsFolders, config)
                .then( res => {
                    const { folders } = res.data
                    const sortedFolders = folders.sort( (a, b) => a.displayName.localeCompare(b.displayName))
                    setFolders(sortedFolders)
                }).catch(e => {
                    console.log(e)
                })
            }
            
        }

        getFlowFolders()
        return () => { mounted = false}
    },[])
    

    return (
        <>
        <div className={classes.root}>
            {/* logo */}
            <div className={classes.logoContainer}>
                <button className={classes.logo} > 
                    <Logo width="135px" height="30px" alt="Logo" />
                </button>
            </div>
            {/* end logo */} 

            <NavItem 
                classes={classes.inner}
                path={['/v1/workflows/manage']}
                SVGIcon={SVGZap()}
                NavName='Manage'
            />

            <div className={`${classes.subNav} subNavButton`}>

                <NavChildrenItem 
                    path={['/manage/flows?showAll=yes']}
                    SVGIcon={SVGFolder()}
                    isLink={true}
                    NavName='All Flows'        
                />
                <NavChildrenItem 
                    path={['/v1/workflows/manage']}
                    SVGIcon={SVGFolder()}
                    NavName='Kosmos'                
                />
                <NavChildrenItem 
                    path={['/pdfextract']}
                    SVGIcon={SVGFolder()}
                    NavName='PDF Workflow'                
                />
                {
                    folders && folders.map( (item: any) => {
                        return (
                            <NavFolderItem 
                                key={item.id}
                                classes={classes.inner}
                                path={`/manage/flows?folderId=${item.id}`}
                                SVGIcon={SVGFolder()}
                                item={item}
                                NavName={`${item.displayName}`}      
                                setSelecteditem={setSelecteditem}
                                setIsDeleteModal={setIsDeleteModal}     
                                setIsRenameModal={setIsRenameModal}
                            />
                        )
                    } )
                }


                <NavChildrenItem 
                    path={['/manage/flows?folderId=NULL']}
                    SVGIcon={SVGFolder()}
                    isLink={true}
                    NavName='Uncategorized'                
                />
                <div>
                    <button type='button' onClick={() => setIsAddModal(true)}> 
                        <div className={classes.inner}>
                            <div className='svgContainer filled' >
                            {SVGFolderFilled()}
                            </div>
                            <span>Add Folder</span>
                        </div>
                    </button>
                </div>
            </div>

            <NavItem 
                classes={classes.inner}
                path={['/runs']}
                SVGIcon={SVGSearch()}
                isLink={true}
                NavName='Runs'
            />

            <NavItem 
                classes={classes.inner}
                path={['/connections']}
                SVGIcon={SVGLink()}
                isLink={true}
                NavName='Connections'
            />

            <NavItem 
                classes={classes.inner}
                path={['/v1/workflows/files']}
                SVGIcon={SVGFile()}
                NavName='Files'
            />


            <div className={classes.divider}></div>

            <div className={classes.navHead}>admin</div>  
            
            {
                user && user.idToken.claims['role'] == 'superadmin' ? 
                <>
                    <NavItem 
                        classes={classes.inner}
                        path={['/v1/admin/users', '/v1/admin/settings']}
                        SVGIcon={SVGSettings()}
                        NavName='Settings'
                    />
                </> : <>
                    <NavItem 
                        classes={classes.inner}
                        path={['/v1/account']}
                        SVGIcon={SVGSettings()}
                        NavName='Settings'
                    />
                </>
            }
            


            <NavItem 
                classes={classes.inner}
                path={['/v1/logout']}
                SVGIcon={SVGLogout()}
                NavName='Logout'
            />

            <HelpButton />

        </div>
            {
                isAddModal && 
                <AddModal 
                    setFolders={setFolders}
                    isOpen={isAddModal}
                    handleClose = { () => setIsAddModal(false) }
                />

            }
            {
                isDeleteModal && 
                <DeleteModal 
                    selectedItem={selectedItem}  
                    setSelecteditem={setSelecteditem}
                    setFolders={setFolders}
                    isOpen={isDeleteModal}
                    handleClose = { () => setIsDeleteModal(false) }                
                />
            }
            {
                isRenameModal && 
                <RenameModal 
                    selectedItem={selectedItem}  
                    setSelecteditem={setSelecteditem}
                    setFolders={setFolders}
                    isOpen={isRenameModal}
                    handleClose = { () => setIsRenameModal(false) }                
                />
            }
        </>
    )
}

export default SideBar