import React from 'react'
import type {FC} from 'react'
import { useHistory, useLocation } from 'react-router'

import { activepiecesFrontendURL } from 'src/utils/requestUrl'

interface NavItemProps {
    classes: string,
    path: Array<string>
    SVGIcon: object
    NavName: string
    isLink?: boolean
}

const NavItem:FC<NavItemProps> = ({classes, path, SVGIcon, NavName, isLink = false } ) => {
    
    const history = useHistory();
    const location = useLocation();
    const currentLocation = location.pathname;

    const navigateTo = (path: string): void => {

        if(isLink){
            const openLink = activepiecesFrontendURL() + path
            window.location.href = openLink
        }else{
            history.push(path)
        }

    }   

    const isActive = (): string | '' => {
        const pathArray = path
        if(pathArray.includes(currentLocation)){
            return 'activeLink'
        }
        return ''
    }



  return (
    <>
        <button type='button' onClick={() => navigateTo(path[0])} className={`${isActive()} navButton` }> 
            <div className={classes}>
                <div className='svgContainer'>
                    {SVGIcon}
                </div>
                <span>
                    {NavName}
                </span>
            </div>
        </button>
    </>
  )
}

export default NavItem