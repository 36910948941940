import React from 'react';

import { SVGQuestion } from 'src/components/SVGIcon';

import type {FC} from 'react'

import { 
    makeStyles 
} from '@material-ui/core';



import type { Theme } from 'src/theme';

const useStyles = makeStyles((theme: Theme) => ({
  root:{},
  helpSection:{
    position:'relative',
    height: '100%',
  },
  helpContainer:{
      position: 'absolute',
      bottom: 0,
      right: 0,
      left: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      width: '219px',
      height: '66px',
      padding: '9px 0px',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '4px',
      backgroundColor: theme.palette.grey[200],
  },
  svgContainer:{
      width: '43px',
      height: '43px',
      borderRadius: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#2fc1fb',
      marginRight: '15px',

      '& svg':{
          width: '26px',
          height: '26px',
          fill: '#fff'
      }
  },
  details:{
    display: 'flex',
    flexDirection: 'column',
    
    '& span':{
        color: theme.palette.text.primary,
        marginBottom: '6px',
    },

    '& a':{
        textDecoration: 'none',
        color: '#2fc1fb',
        fontSize: '16px',
        padding: '0px',
        letterSpacing: '0.4px',
        margin: '0px',
        '&:hover':{
            textDecoration: 'underline'
        }
    }
}
}))

const HelpButton = () => {

  const classes = useStyles()

  return (
    <div className={classes.helpSection}>
      <div className={classes.helpContainer}>
          <div className={classes.svgContainer}>
              {SVGQuestion()}
          </div>
          <div className={classes.details}>
              <span>
                  Need Help?
              </span>
              <div>
                  <a 
                      href="https://blackmartech.freshdesk.com/support/home"
                      target="_blank"
                      className='support-button'>
                      Contact Support
                  </a>
              </div>
          </div>
      </div>
  </div>
  );
};

export default HelpButton;
