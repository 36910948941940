import _ from 'lodash';
import {
  colors,
  createMuiTheme,
  responsiveFontSizes
} from '@material-ui/core';
import type { Theme as MuiTheme } from '@material-ui/core/styles/createMuiTheme';
import type { Shadows as MuiShadows } from '@material-ui/core/styles/shadows';
import type {
  Palette as MuiPalette,
  TypeBackground as MuiTypeBackground
} from '@material-ui/core/styles/createPalette';
import { THEMES } from 'src/constants';
import { softShadows, strongShadows } from './shadows';
import { darkPalette, lightPalette } from './palette';
import { tableStyle, designtable } from './table'

import typography from './typography';

interface TypeBackground extends MuiTypeBackground {
  dark: string;
  light: string;
  border: string;
  sidebar: string;
  paper: string;
  bg: string;
  card: string;
  icon: string;
  nav: string;
}

interface Palette extends MuiPalette {
  background: TypeBackground;
}

export interface Theme extends MuiTheme {
  name: string;
  palette: Palette;
}

type Direction = 'ltr';

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  theme?: string;
}

interface ThemeOptions {
  name?: string;
  direction?: Direction;
  typography?: Record<string, any>;
  overrides?: Record<string, any>;
  palette?: Record<string, any>;
  shadows?: MuiShadows;
}

const baseOptions: ThemeOptions = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32
      }
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)'
      }
    },
    MuiTable: tableStyle.MuiTable,
    MuiTableRow: tableStyle.MuiTableRow,
    MuiTableCell: tableStyle.MuiTableCell,
    MuiCollapse: tableStyle.MuiCollapse,
  },
};

const lightTable = designtable('light')
const darkTable  = designtable('dark')

const themesOptions: ThemeOptions[] = [
  {
    name: THEMES.LIGHT,
    overrides: {
      MuiTable : lightTable.MuiTable,
      MuiTableRow : lightTable.MuiTableRow,
      MuiTableCell : lightTable.MuiTableCell,
      MuiCollapse : lightTable.MuiCollapse,
    },
    palette: lightPalette,
    shadows: softShadows
  },
  {
    name: THEMES.ONE_DARK,
    overrides: {
      MuiTable : darkTable.MuiTable,
      MuiTableRow : darkTable.MuiTableRow,
      MuiTableCell : darkTable.MuiTableCell,
      MuiCollapse : darkTable.MuiCollapse,
    },
    palette: darkPalette,
    shadows: strongShadows,
  }
];

export const createTheme = (config: ThemeConfig = {}): Theme => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createMuiTheme(
    _.merge(
      {},
      baseOptions,
      themeOptions,
      { direction: config.direction }
    )
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme as Theme;
}
