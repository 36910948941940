import React from 'react'
import { useHistory, useLocation } from 'react-router';
import { 
    makeStyles 
} from '@material-ui/core';


import type { Theme } from 'src/theme';
import { activepiecesFrontendURL } from 'src/utils/requestUrl';
interface NavChildrenItemProps {
    path: Array<string>
    SVGIcon: object
    NavName: string
    isLink?: boolean
    extraClass?: string
}

const useStyles = makeStyles((theme: Theme) => ({
    root:{},
    inner:{
        width: '100%',
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: '5px',
        paddingRight: '5px',
        height: '24px',
        color: '#ffffff',
        '& .btn-options':{
            isplay: 'none'
        },
        '&:hover':{
            '& .btn-options':{
                    display: 'flex'
            }
        },
        '& .svgContainer':{
            marginTop: '1px',
            display: 'flex',
            width: '35px',
            fill: 'none',
            stroke: theme.palette.grey[100],
        },

        '& span':{
            color: theme.palette.grey[50],
            display: 'flex',
            flex: 1
        },

  
    },

}))

const NavChildrenItem = ({path, SVGIcon, NavName, isLink = false, extraClass  = ''} : NavChildrenItemProps) => {

    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const currentLocation = location.pathname;


    const navigateTo = (path: string): void => {

        if(isLink){
            const openLink = activepiecesFrontendURL() + path
            window.location.href = openLink
        }else{
            history.push(path)
        }

    }   

    const isActive = (): string | '' => {
        const pathArray = path
        if(pathArray.includes(currentLocation)){
            return 'selectedLink'
        }
        return ''
    }

  return (
    
    <>

        <div>
            <button type='button'  onClick={ () => navigateTo(path[0])} className={`${isActive()}` }> 
                <div className={classes.inner}>
                    <div className={`svgContainer  ${extraClass}`} >
                        {SVGIcon}
                    </div>
                    <span>
                        {NavName}
                    </span>
                </div>
            </button>
        </div>
    </>
  )
}

export default NavChildrenItem