import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/v1" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/Login'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/authcheck',
    component: lazy(() => import('src/views/auth/AuthCheckView'))
  },
  {
    exact: true,
    path: '/setup-required',
    component: lazy(() => import('src/views/errors/UserNotLinked'))
  },
  {
    exact: true,
    path: '/activepieces/auth',
    guard: AuthGuard,
    component: lazy(() => import('src/views/activepieces'))
    //layout: ActivepiecesLayout,
  },
  {
    exact: true,
    path: '/pdfextract',
    guard: AuthGuard,
    component: lazy(() => import('src/views/extractpdf'))
    //layout: ActivepiecesLayout,
  },
  {
    path: '/v1',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/v1/logout',
        component: lazy(() => import('src/views/auth/LogoutView'))
      },
      {
        exact: true,
        path: '/v1/account',
        component: lazy(() => import('src/views/account/AccountView'))
      },
      {
        exact: true,
        path: '/v1/admin/users',
        component: lazy(() => import('src/views/user/UserListView'))
      },
      {
        exact: true,
        path: '/v1/admin/users/manage/:userid',
        component: lazy(() => import('src/views/account/EditAccount'))
      },
      {
        exact: true,
        path: '/v1/admin/settings',
        component: lazy(() => import('src/views/settings'))
      },
      {
        exact: true,
        path: '/v1/workflows/manage',
        component: lazy(() => import('src/views/workflows/ManageView'))
      },
      {
        exact: true,
        path: '/v1/workflows/manage/:workflowid',
        component: lazy(() => import('src/views/workflows/DetailsView'))
      },
      {
        exact: true,
        path: '/v1/workflows/run/:workflowid',
        component: lazy(() => import('src/views/workflows/RunView'))
      },
      {
        exact: true,
        path: '/v1/workflows/files',
        component: lazy(() => import('src/views/workflows/FilesView'))
      },
      {
        exact: true,
        path: '/v1',
        component: () => <Redirect to="/v1/workflows/manage" />
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default routes;
